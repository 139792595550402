import React, { useState } from 'react'
import TextInput from '../components/forms/TextInput';
import TextareaInput from '../components/forms/TextareaInput';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from 'axios';

const ContactUsPage = ({data}) => {
    
    const {register, handleSubmit, watch, errors, reset} = useForm();
    const watchFields = watch();
    const [notifyMessage, setNotifyMessage] = useState('');
    
    
    const onSubmit = (data, e) => {

        const response =  axios.post(
            '../send/contact',
            data ,
            { headers: { 'Content-Type': 'application/json' } }
        ).then(res => {

            if(res.data.status === true){
                //console.log('true')
                setNotifyMessage('Thank you we will be in touch shortly.')
                e.target.reset();

            }else {
               // console.log('false' + res.data)
                setNotifyMessage(res.data.error);
            }
           
        }) 
    
      

    }

    const FormMessage = ({message}) => {
        return (
            <>
            <div className="notify-overlay" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)} role="button" tabIndex="0">
                <div className="notification" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)}  role="button" tabIndex="-1">
                    <p className="success-message">
                        {message}
                    </p>
                </div>
            </div>
            
           
            </>
        );
    }



    return (
        <Layout pageClass="contact-us-page">
        <SEO title="Contact Us" />
        <main className="main">
            <div className="typography inner">

                <h1>Contact Us</h1>
                <div className="contact-holder">
                    <div className="contact-form">
                        <p className="center">Fill out the form below and we will get back to you as soon as possible!</p>

                        <form name="contact" method="post" onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" name="bot-field" />

                            <TextInput reg={register({required: "Name is Required"})}  name="name" label="Name" type="text" errors={errors.name} />

                            <TextInput reg={register({required: "Email is Required"})}  name="email" label="Email" type="text" errors={errors.email} />

                            <TextInput reg={register()}  name="phone" label="Phone" type="text" errors={errors.phone} />

                            <TextareaInput reg={register({required: "Message is Required"})}  name="message" label="Message"  errors={errors.message} />
                            <input type="submit" className="submit-btn btn" value="Submit" />
                        </form>
                    </div> 
                </div>
            </div>
        </main>
        {notifyMessage ? <FormMessage message={notifyMessage} /> : ''}
        </Layout>

    )
}

export default ContactUsPage